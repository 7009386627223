<template>
    <div>
        <periods-list />
    </div>
</template>

<script>
import periodsList from '@/components/admin/games/evaluations/periodsList';

export default {
    name: 'AdminPeriods',
    components: { periodsList },
    data: () => ({})
};
</script>
