<template>
    <v-row>
        <v-col cols="12">
            <base-card>
                <v-card-title>
                    <div class="d-flex justify-space-between flex-wrap">
                        <v-btn
                            class="ma-2"
                            dark
                            color="primary"
                            @click="createItemHandler"
                        >
                            <v-icon>mdi-plus</v-icon>
                            {{ $t('admin.classifiers.periods.add') }}
                        </v-btn>

                        <v-btn
                            class="ma-2"
                            dark
                            color="primary"
                            @click="initializePeriod"
                        >
                            <v-icon>mdi-power</v-icon>
                            {{ $t('admin.classifiers.periods.initializePeriod') }}
                        </v-btn>
                    </div>
                </v-card-title>
                <v-card-title>
                    {{ $t('admin.classifiers.periods.title') }}
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />
                    <v-spacer />

                    <v-text-field
                        v-model="search"
                        :append-icon="!search ? 'mdi-magnify' : undefined"
                        clearable
                        :label="$t('common.search')"
                        single-line
                        hide-details
                    />
                </v-card-title>

                <!-- :pagination.sync="pagination" -->
                <v-data-table
                    :options.sync="options"
                    :headers="headers"
                    :footer-props="{ 'items-per-page-options': [5, 10, 30, 50, -1] }"
                    :items="items"
                    class="elevation-1"
                    :sort-by="sortby"
                    :multi-sort="true"
                    :search="search"
                    :custom-filter="filterTableItems"
                    @click:row="editItemHandler"
                >
                    <template #item.id="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>

                    <template #item.name="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.description="{ value }">
                        <div :inner-html.prop="value | highlight(search)" />
                    </template>
                    <template #item.periods_id_to_compare="{ value }">
                        <div :inner-html.prop="getPeriodToCompareTextToShow(value) | highlight(search)" />
                    </template>
                    <template #item.is_active="{ value }">
                        <ActiveColumn :value="value" />
                    </template>

                    <template v-slot:item.created_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>
                    <template #item.updated_at="{ value }">
                        <div :inner-html.prop="dateHelper.getDateTimeTableFormat(value)" />
                    </template>

                    <!--                    <template v-slot:item.role_id="{ item }">
    {{ itemsRoles.find((r) => r.id_role == item.role_id).name }}
</template>-->

                    <template v-slot:item.actions="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                    small
                                    class="mr-2"
                                    v-bind="attrs"
                                    @click.stop="editItemHandler(item)"
                                    v-on="on"
                                >
                                    mdi-pencil
                                </v-icon>
                            </template>

                            <span>{{ $t('common.edit') }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data>
                        <v-btn
                            color="primary"
                            @click="fetchData"
                        >
                            {{ $t('admin.common.resetData') }}
                        </v-btn>
                    </template>
                </v-data-table>
            </base-card>

            <classifier-create
                v-if="dialog"
                :dialog="dialog"
                :item="itemSelected"
                messages-id="periods"
                :url-main="urlMain"
                @update:dialog="dialog = $event"
                @update:table="fetchData"
            >
                <template #option1>
                    <div
                        :value="selectedOptions.periodsIdToCompare"
                        name="periods_id_to_compare"
                    >
                        <v-autocomplete
                            v-model="selectedOptions.periodsIdToCompare"
                            :items="items.filter(period => !itemSelected || period.id != itemSelected?.id)"
                            :label="$t('admin.classifiers.periods.periodToCompare')"
                            item-text="name"
                            item-value="id"
                            clearable
                        />
                    </div>
                </template>

                <template #option2>
                    <div
                        :value="selectedOptions.isActive ? 1 : 0"
                        name="is_active"
                    >
                        <v-switch
                            v-model="selectedOptions.isActive"
                            inset
                            :label="$t('admin.classifiers.periods.activate')"
                        />
                    </div>
                </template>
            </classifier-create>

            <confirm-dialog ref="confirm" />
        </v-col>
    </v-row>
</template>

<script>
import ConfirmDialog from '@/components/common/ConfirmDialog';

import EvaluationsAPIService from '@/services/EvaluationsAPIService';
import classifierCreate from '@/components/admin/games/classifiers/classifierCreate';
import listAdminMixin from '@/mixins/listAdminMixin';
import ActiveColumn from '@/components/common/table/ActiveColumn';

export default {
    components: { classifierCreate, ConfirmDialog, ActiveColumn },
    mixins: [listAdminMixin],
    data: function () {
        return {
            urlMain: 'admin/games/evaluations/periods',
            headers: [
                {
                    text: 'Id',
                    align: 'start',
                    sortable: true,
                    value: 'id'
                },
                {
                    text: this.$t('common.name'),
                    value: 'name'
                },
                {
                    text: this.$t('common.description'),
                    value: 'description'
                },
                {
                    text: this.$t('admin.classifiers.periods.periodToCompare'),
                    value: 'periods_id_to_compare'
                },
                {
                    text: 'Activo',
                    value: 'is_active'
                },
                {
                    text: this.$t('admin.common.createdAt'),
                    value: 'created_at'
                },
                {
                    text: this.$t('admin.common.updatedAt'),
                    value: 'updated_at'
                },
                // { text: "Role", value: "role_id" },
                {
                    text: this.$t('admin.common.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            selectedOptions: {
                periodsIdToCompare: null,
                isActive: true
            }
        };
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    /* computed: {
      ...mapGetters(['role'])
  }, */

    methods: {
        initialize($data) {
            this.items = $data.periods ?? [];

            // this.pagination.totalItems = $data?.meta?.page?.total ?? 0;
        },

        getPeriodToCompareTextToShow(period_id) {
            if (!period_id) return '';

            const period = this.items.find(period => period.id == period_id);

            if (!period) return '';

            return `#${period.id} - ${period.name}`;
        },

        createItemHandler(item) {
            this.selectedOptions.periodsIdToCompare = null;
            this.selectedOptions.isActive = true;

            this.createItem(item);
        },

        editItemHandler(item) {
            this.selectedOptions.periodsIdToCompare = item.periods_id_to_compare;
            this.selectedOptions.isActive = item.is_active;

            this.editItem(item);
        },

        async initializePeriod() {
            if (
                await this.$refs.confirm.open(
                    this.$t('admin.classifiers.periods.initializePeriod'),
                    this.$t('admin.classifiers.periods.initializePeriodConfirm')
                )
            ) {
                await EvaluationsAPIService.initializePeriod();
            }
        }
    }
};
</script>
